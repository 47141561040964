import { HeadingXLarge } from "baseui/typography";
import React, { useState } from "react";
import { KIND } from "baseui/button";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  checkAuthorizsed,
  getCallBackLater,
} from "../../services/customerPortal";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { IconButton } from "../../components/IconButton";
import { Avatar } from "baseui/avatar";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { TextButton } from "../../components/TextButton";
import AuthorizationDownloadSvg from "../../assets/img/svg/AuthorizeDownloadSvg";
import ShieldIconSvg from "../../assets/img/svg/ShieldIconSvg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ChatIconSvg from "../../assets/img/svg/ChatIconSvg";
import InputComponent from "./InputComponent";
import { axiosWithAuth } from "../../providers/AuthProvider";
import { toast } from "react-toastify";
import { isExpired } from "react-jwt";
import DocumentDowload from "./DocumentDowload";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import moment from "moment";

const AuthorizeContainer = () => {
  const [formData, setFormData] = useState({});
  const location = useLocation();
  let is_document_authorize_configured = useSelector(
    (e) => e.layoutReduceData.is_document_authorize_configured
  );
  let document_authorize_data = useSelector(
    (e) => e.layoutReduceData.document_authorize_configurations
  );

  const yupValidationSchema = (documentAuthorizeData) => {
    const schemaObject = {};

    documentAuthorizeData.forEach((item) => {
      switch (item.ft) {
        case "NUM":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.number().typeError("Required").min(1, "Enter Valid Number");
          break;
        case "STR":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.string().required("This field is required");
          break;
        case "DT":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.date().typeError("Required").required("Date is required");
          break;
        // Add additional cases for other field types if needed
        default:
          break;
      }
    });

    return Yup.object().shape(schemaObject);
  };

  const validationSchema = yupValidationSchema(document_authorize_data);

  // Now, you can use the generated `validationSchema` in your Yup validation.

  const hash = location?.pathname?.split("/")[1];

  const isMobileView = window.innerWidth <= 500;
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [`PROMISE-TO-PAY-${count} `],
    async () => {
      return await getCallBackLater({ entity: "PROMISE_TO_PAY" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let dispatch = useDispatch();

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  // const hash = location.pathname.split("/")[1];
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  // const { default_color } = useSelector((s) => s.layoutReduceData);
  const { default_color, customer, organization, referenceData } = useSelector(
    (s) => s.layoutReduceData
  );
  const authorizeCall = useMutation((data) => checkAuthorizsed(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      if (data.data.doc.expired_at < Date.now()) {
      }
      localStorage.setItem("AuthorizationToken", data.data.doc.token);
      toast.success(data.data.message);
    },
  });

  let requestDataValied = false;

  if (data?.data?.doc.length == 0) {
    requestDataValied = true;
  }
  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
    // reValidateMode: "all",
    // resolver: yupResolver(validationSchema),
  });
  const testWatch = watch();

  const onSubmit = async (data) => {
    const convertToTimestamp = (value) => {
      if (value instanceof Date) {
        return moment
          .tz(
            `${value ? value.getFullYear() : ""}-${
              value ? value.getMonth() + 1 : ""
            }-${value ? value.getDate() : ""}`,
            "YYYY-MM-DD",
            timeZone
          )
          .startOf("day")
          .utc()
          .valueOf();
      } else if (typeof value === "number") {
        return value.toString();
      }
      return value;
    };
    for (const key in data) {
      if (data[key] instanceof Date) {
        data[key] = convertToTimestamp(data[key]);
      } else if (typeof data[key] === "object" && !Array.isArray(data[key])) {
        for (const objKey in data[key]) {
          // Convert each value in the nested object
          data[`${key}.${objKey}`] = convertToTimestamp(data[key][objKey]);
        }
        delete data[key];
      } else {
        data[key] = convertToTimestamp(data[key]);
      }
    }
    // Make the API call with the modified data
    await authorizeCall.mutateAsync(data);
  };

  let totalAmtLength = total_outstanding_amount;

  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;

  const color = useSelector((s) => s.layoutReduceData);

  const authorizeDocAuthToken = localStorage.getItem("AuthorizationToken");

  return (
    // <DocumentDowload/>
    <div
      className="invoices-container --req_modifi"
      style={{ overflow: `${authorizeDocAuthToken ? "" : "scroll"} ` }}
    >
      <div>
        <div className="invoices-header-container">
          <div className="invoices-header___message-conatiner">
            <div>
              <div className="sidebar-top">
                <NavLink
                  to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {widths <= 500 ? (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          size="scale1200"
                        />
                      ) : organizationData &&
                        organizationData.customer_portal_ui &&
                        organizationData.customer_portal_ui.logo ? (
                        <div
                          style={{
                            maxWidth: `95px`,
                            minWidth: `18px`,
                            maxHeight: `48px`,
                          }}
                        >
                          <img
                            src={
                              organizationData &&
                              organizationData.customer_portal_ui &&
                              organizationData.customer_portal_ui.logo
                                ? organizationData.customer_portal_ui.logo
                                : null
                            }
                            alt="customer_Logo"
                            className="customer_portal_logo"
                          />
                        </div>
                      ) : (
                        <Avatar
                          overrides={{
                            Avatar: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                              }),
                            },
                            Root: {
                              style: ({ $theme }) => ({
                                borderTopLeftRadius: $theme.borders.radius100,
                                borderTopRightRadius: $theme.borders.radius100,
                                borderBottomRightRadius:
                                  $theme.borders.radius100,
                                borderBottomLeftRadius:
                                  $theme.borders.radius100,
                                backgroundColor: "#F8F8FB",
                                width: "48px",
                                objectFit: "contain",
                              }),
                            },
                            Initials: {
                              style: () => ({
                                color: default_color,
                                fontSize: "14px",
                                fontWeight: "700",
                                objectFit: "contain",
                              }),
                            },
                            Avatar: {
                              style: () => ({
                                objectFit: "contain",
                                width: "100px",
                              }),
                            },
                          }}
                          name={organizationData?.name}
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          // size="scale1200"
                        />
                      )}

                      <div className="org-name">
                        <div style={{ display: "flex", gap: "7.5px" }}>
                          <div
                            className="org_name"
                            $style={{ color: "#333860" }}
                          >
                            {/* Seshavalli Vaishnava matriculation school */}
                            {organizationData?.name}
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              color: "#787878",
                              display: "flex",
                              gap: "2px",
                              // hap: "10px",
                              flexDirection: `${
                                totalAmtLength &&
                                totalAmtLength.toString().length > 15
                                  ? "column"
                                  : "row"
                              }`,
                            }}
                          >
                            <div className="totl-ots-mob"> Total O/S: </div>
                            <div className="totl_ots-web">
                              {" "}
                              Total Outstanding:{" "}
                            </div>

                            {/* <>Total Outstanding</> */}
                            <NumericFormat
                              displayType="text"
                              value={
                                total_outstanding_amount
                                  ? total_outstanding_amount
                                  : 0
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(values) => (
                                <div style={{ paddingLeft: "2px" }}>
                                  {currencyList[currency]
                                    ? currencyList[currency]
                                    : currency}{" "}
                                  {values}
                                </div>
                              )}
                            />
                          </div>
                          <div></div>
                        </div>
                        {/* {!isMobileView ? (
                          <div
                            className="custom-container"
                            style={{ position: "relative", top: "8px" }}
                          >
                            <ChatIconSvg />
                            <div
                              className="custom-text"
                              style={{ color: default_color }}
                            >
                              Chat With Us
                            </div>
                          </div>
                        ) : (
                          <div
                            className="custom-container"
                            style={{ position: "relative", top: "8px" }}
                          >
                            <div
                              className="custom-text"
                              style={{ color: default_color }}
                            >
                              Chat With Us
                            </div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="invoices-header___nav-buttons">
              {accessValue?.is_mini_miranda_configured && (
                <div>
                  <div
                    class="button-tilt"
                    onClick={() => {
                      dispatch(setDebtorRightsModal(true));
                    }}
                  >
                    <IconButton kind={KIND.tertiary}>
                      <DebtorRight size={18} />
                      <span class="label-hidden">Consumer Rights</span>
                    </IconButton>
                  </div>
                </div>
              )}
              {isMobileView ? (
                <></>
              ) : (
                <div
                  style={{ display: "flex" }}
                  class="button-tilt email-btn"
                  onClick={() => {
                    sendEmail();
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <i className="mx-icon-Vector-2" />
                    <span class="label-hidden">Email us</span>
                  </IconButton>
                </div>
              )}

              {/* <div class="button-tilt email-btn">
                <IconButton kind={KIND.tertiary}>
                  <ShieldIconSvg />
                  <span class="label-hidden">Debtor Rights</span>
                </IconButton>
              </div> */}

              {accessValue && accessValue.is_callback && (
                <div>
                  <NavLink
                    to={`/${hash}/requesting-calls${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`}
                  >
                    <div class="button-tilt">
                      <IconButton kind={KIND.tertiary}>
                        <i className="mx-icon-call-back-2" />
                        <span class="label-hidden">Call Back Request</span>
                      </IconButton>
                    </div>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
        {isExpired(authorizeDocAuthToken) ? (
          <div className="back__btn-comp">
            {/* COMPONENTS STARTS */}
            <>
              {
                <>
                  <div className="rq__call__form__wraper --modifier">
                    <div
                      className="req__show_d"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "",
                        }}
                      >
                        <div className="--request-call">
                          <NavLink to={`/${hash}`}>
                            <div
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                border: "0.5px solid #cdced9",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <i className="mx-icon-Group-16969" />
                            </div>
                          </NavLink>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <HeadingXLarge>Authorization Required</HeadingXLarge>
                        </div>
                      </div>
                      <div>
                        <AuthorizationDownloadSvg />
                      </div>
                      <div>
                        <div>
                          {/* <DynamicForm formConfig={config} onSubmit={onSubmit} hash="yourHash" /> */}
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {document_authorize_data.map((item) => (
                              <Controller
                                key={`${_.get(item, "fm", "").toLowerCase()}_${
                                  item.fk
                                }`}
                                control={control}
                                name={`${_.get(item, "fm", "").toLowerCase()}_${
                                  item.fk
                                }`}
                                render={({ field }) => {
                                  return (
                                    <div
                                      style={{
                                        left: 0,
                                        top: 0,
                                        width: "250px",
                                      }}
                                    >
                                      <InputComponent
                                        {...field}
                                        item={item}
                                        value={field.value}
                                        onChange={(e) => {
                                          const newValue =
                                            item.ft === "DT"
                                              ? e.date
                                              : e.target.value;
                                          field.onChange(newValue);
                                        }}
                                        label={item.lb}
                                        error={
                                          errors[field.name] &&
                                          errors[field.name].message
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              />
                            ))}

                            {/* Next and Cancel Buttons */}
                            <div
                              className="frame"
                              style={{
                                alignItems: "flex-start",
                                display: "inline-flex",
                                gap: "20px",
                                left: "50px",
                                position: "relative",
                              }}
                            >
                              <NavLink to={`/${hash}`}>
                                <TextButton
                                  type="button"
                                  kind="tertiary"
                                  size="compact"
                                  fullWidth
                                >
                                  Cancel
                                </TextButton>
                              </NavLink>

                              <TextButton
                                type="submit"
                                kind="primary"
                                size="compact"
                                fullWidth
                              >
                                Next
                              </TextButton>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          </div>
        ) : (
          <DocumentDowload />
        )}
      </div>
    </div>
  );
};

export default AuthorizeContainer;
