import { Avatar } from "baseui/avatar";
import { IconButton } from "../../components/IconButton";
import React, { useMemo, useRef, useState } from "react";
import { KIND, SIZE } from "baseui/button";
import { useForm, Controller } from "react-hook-form";
// import moment from "moment";
import {
  checkAuthorizsed,
  getCallBackLater,
  getEsignTemplate,
  sendTemplateContent,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { NumericFormat } from "react-number-format";
import _ from "lodash";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import {
  refetachLayoutData,
  setDebtorRightsModal,
} from "../../redux/Layout/action";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import DrawingBoard from "../../components/DrawingBoard/DrawingBoard";
import { TextButton } from "../../components/TextButton";
import { useGeolocated } from "react-geolocated";
import FontStyleSelector from "../../components/FontStyleSelector/FontStyleSelector";
import moment from "moment";
import { LabelLarge } from "baseui/typography";
import { TextBox } from "../../components/TextBox";
import queryClient from "../../providers/queryClient";

const ESignContentContainer = ({ refetch, coords }) => {
  let dispatch = useDispatch();
  const { organization, default_color, callBackData, refetchData, customer } =
    useSelector((e) => e?.layoutReduceData);
  const customizedData = useSelector((state) => state?.customizeReducer?.data);
  const fontStyleRef = useRef();
  const [isBlob, setBlob] = useState(null);
  const { id, ...rest } = useParams();

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  let getIsLocation = getSearchParams.get("is_location");
  const getToken = getSearchParams.get("token");

  const authorizeDocAuthToken = localStorage.getItem("AuthorizationToken");

  const headers = { Authorization: `Bearer ${authorizeDocAuthToken}` };

  const [errorMessage, setErrorMessage] = useState("");
  const [isText, setIsText] = useState("");

  const navigate = useNavigate();

  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const { postValue } = useMemo(() => {
    let postValue = {
      ...(coords?.latitude && coords?.longitude
        ? { sign_location: `${coords?.latitude}, ${coords?.longitude}` }
        : {}),
      sign_time: moment()?.tz(organization.time_zone)?.format("hh:mm A"),
      sign_date: moment()
        .tz(organization?.time_zone)
        .format(organization.date_format),
      sign_date_time: `${moment()
        .tz(organization?.time_zone)
        .format(organization?.date_format)} ${moment()
        .tz(organization?.time_zone)
        .format("hh:mm A")}`,
    };

    return { postValue };
  }, [coords?.latitude, coords?.longitude]);

  const { data, isLoading } = useQuery(
    [`E-SIGN_CONTENT_${refetchData}`],
    async () => {
      return await getEsignTemplate({
        signId: id,
        headers: `Bearer ${getToken}`,
        ...postValue,
      });
    },
    {
      onError: (data) => {
        // data?.response?.data?.message
        toast.error("You have no pending e-signature requests.");
        navigate(`/${hash}`);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { hash } = useParams();

  const sendContentData = useMutation(
    (data) =>
      sendTemplateContent({
        signId: id,
        headers: `Bearer ${getToken}`,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        navigate(`/${hash}/esignSuccess`);
        refetch();
        toast.success(data.data.message);
      },
    }
  );

  // moment(data.follow_up_date).utc().valueOf();

  let callBackDataValied = false;

  if (callBackData) {
    callBackDataValied = true;
  }

  const isCanvasEmpty = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    // Check if any pixel is not transparent
    return !pixelBuffer.some((color) => color !== 0);
  };

  const handleBlob = (generatedBlob) => {
    // setBlob(generatedBlob);
  };

  const saveContent = () => {
    if (isCanvasEmpty()) {
      setErrorMessage("eSignature is required.");
      return;
    }

    const canvas = canvasRef.current;
    canvas.toBlob(async (blob) => {
      const formData = new FormData();

      formData.append("file", blob);
      formData.append("sign_location", postValue.sign_location);
      formData.append("sign_time", postValue.sign_time);
      formData.append("sign_date", postValue.sign_date);
      formData.append("sign_date_time", postValue.sign_date_time);

      await sendContentData.mutateAsync(formData);
    }, "image/png");
  };

  const handleGenerateBlob = () => {
    if (isText.length === 0) {
      setErrorMessage("eSignature is required.");
      return;
    } else {
      setErrorMessage("");
    }
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error("Canvas is not rendered or ref is not assigned!");
      return;
    }

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Failed to get canvas context!");
      return;
    }

    const text = isText; // Replace with dynamic text if needed
    const fontSize = 24;

    // Configure canvas dimensions and style
    canvas.width = 400;
    canvas.height = 100;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = `${fontSize}px Sacramento`;
    ctx.fillStyle = "#000";
    ctx.textBaseline = "middle";
    ctx.fillText(text, 10, canvas.height / 2);

    // Convert the canvas to a Blob
    canvas.toBlob(async (blobData) => {
      const formData = new FormData();

      formData.append("file", blobData);
      formData.append("sign_location", postValue.sign_location);
      formData.append("sign_time", postValue.sign_time);
      formData.append("sign_date", postValue.sign_date);
      formData.append("sign_date_time", postValue.sign_date_time);

      await sendContentData.mutateAsync(formData);
    }, "image/png");
  };

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);
  // const { total_outstanding_amount = 0 } = customer;

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let totalAmtLength = total_outstanding_amount;

  const symbol = currencyList[currency] ? currencyList[currency] : currency;
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;

  // if (getIsLocation && !coords?.latitude && !coords?.longitude) {
  //   return (
  //     <div className="invoices-container">
  //       <div className="invoices-header-container">
  //         <div className="invoices-header___message-conatiner">
  //           <div
  //             style={{
  //               height: "calc(100vh - 50px)",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               flex: "1",
  //             }}
  //           >
  //             <LabelLarge>Allow Location Access</LabelLarge>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="invoices-container">
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            <div className="sidebar-top">
              <NavLink
                to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {widths <= 500 ? (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        size="scale1200"
                      />
                    ) : organizationData &&
                      organizationData.customer_portal_ui &&
                      organizationData.customer_portal_ui.logo ? (
                      <div
                        style={{
                          maxWidth: `95px`,
                          minWidth: `18px`,
                          maxHeight: `48px`,
                        }}
                      >
                        <img
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          alt="customer_Logo"
                          className="customer_portal_logo"
                        />
                      </div>
                    ) : (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                              objectFit: "contain",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                              objectFit: "contain",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                              width: "100px",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        // size="scale1200"
                      />
                    )}

                    <div className="org-name">
                      <div>
                        <div className="org_name" $style={{ color: "#333860" }}>
                          {organizationData?.name}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            color: "#787878",
                            display: "flex",
                            gap: "2px",
                            // hap: "10px",
                            flexDirection: `${
                              totalAmtLength &&
                              totalAmtLength.toString().length > 15
                                ? "column"
                                : "row"
                            }`,
                          }}
                        >
                          <div className="totl-ots-mob"> Total O/S: </div>
                          <div className="totl_ots-web">
                            {" "}
                            Total Outstanding:{" "}
                          </div>
                          {/* <>Total Outstanding</> */}
                          <NumericFormat
                            displayType="text"
                            value={
                              total_outstanding_amount
                                ? total_outstanding_amount
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <div style={{ paddingLeft: "3px" }}>
                                {symbol} {values}
                              </div>
                            )}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>

          {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
          {/* <div className="invoices-header___nav-buttons">
            {accessValue?.is_mini_miranda_configured && (
              <div>
                <div
                  class="button-tilt"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <DebtorRight size={18} />
                    <span class="label-hidden">Consumer Rights</span>
                  </IconButton>
                </div>
              </div>
            )}
            <div>
              <div
                class="button-tilt email-btn"
                onClick={() => {
                  sendEmail();
                }}
              >
                <IconButton kind={KIND.tertiary}>
                  <i className="mx-icon-Vector-2" />
                  <span class="label-hidden">Email us</span>
                </IconButton>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="rq__date__form__wraper">
        <div className="back__btn-comp esign_container">
          <div className="esign_content_conatiner">
            <LabelLarge $style={{ textAlign: "center" }}>
              {_.get(data, "data.doc.header", "")}
            </LabelLarge>
            <p
              dangerouslySetInnerHTML={{
                __html: _.get(data, "data.doc.body", ""),
              }}
            ></p>

            {_.get(data, "data.doc.sign_method", "") === "CNV" ? (
              <DrawingBoard
                canvasRef={canvasRef}
                contextRef={contextRef}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            ) : (
              <></>
            )}

            {_.get(data, "data.doc.sign_method", "") !== "CNV" ? (
              <div style={{ marginTop: "15px" }}>
                <TextBox
                  size={SIZE.compact}
                  name="esign-typo"
                  label={"Type Your Name Here"}
                  placeholder={"Type Your Name Here"}
                  value={isText}
                  error={errorMessage}
                  onChange={(e) => setIsText(e.target.value)}
                />
                <FontStyleSelector
                  ref={fontStyleRef}
                  fontStyleRef
                  canvasRef={canvasRef}
                  isText={isText}
                  onBlobGenerated={handleBlob}
                  setBlob={setBlob}
                />
              </div>
            ) : (
              <></>
            )}

            <p
              dangerouslySetInnerHTML={{
                __html: _.get(data, "data.doc.post_sign_content", ""),
              }}
            ></p>
          </div>
          <div
            // class="tabviewpayment"
            style={{
              bottom: " 0 %",
              width: "100 %",
              borderRadius: "5px",
              borderTop: " 1px solid #CDCED9",
              width: "100%",
              background: default_color,
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "10px",
              padding: "10px",
            }}
          >
            <TextButton
              kind={KIND.secondary}
              size={SIZE.mini}
              type="button"
              isLoading={sendContentData.isLoading}
              disabled={sendContentData.isLoading}
              onClick={() => {
                if (_.get(data, "data.doc.sign_method", "") === "CNV") {
                  saveContent();
                } else {
                  handleGenerateBlob();
                }
              }}
            >
              Save
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ESignContentContainer;
