import { Avatar } from "baseui/avatar";
import {
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
// import PaymentAmountImg from "../../../src/assets/img/payment-amount.png";
import { TextButton } from "../../components/TextButton";
import TableSelectedFooter from "../../components/TableSelectedFooter/TableSelectedFooter";
import { values } from "lodash";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { FormattedMessage } from "react-intl";
import PaymentAmountImg from "../../assets/img/svg/PlanendAmountImg";
import CalendarSvg from "../../assets/img/svg/CalendarSvg";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import _ from "lodash";
import { paymentAmount } from "../../redux/Layout/action";
import { LoanBroker } from "../../constants/IndustrialSector";

const PaymentAmount = ({ isGoBackBtn = true, data, setCount, count }) => {
  // Header start
  let accessValue = useSelector((e) => e.layoutReduceData.accessData);
  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let dispatch = useDispatch();
  let navigate = useNavigate();
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const { default_color, customer, organization, referenceData } = useSelector(
    (s) => s.layoutReduceData
  );

  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  let showActiveUser = addSearchParams.get("i");

  const symbol = currencyList[customer?.currency];
  const installmentsSymbol = currencyList[
    data &&
      data?.request_time_data_payment_plan &&
      data?.request_time_data_payment_plan[0] &&
      data?.request_time_data_payment_plan[0].promise_amount &&
      data?.request_time_data_payment_plan[0].promise_amount?.currency
  ]
    ? currencyList[
        data &&
          data?.request_time_data_payment_plan &&
          data?.request_time_data_payment_plan[0] &&
          data?.request_time_data_payment_plan[0].promise_amount &&
          data?.request_time_data_payment_plan[0].promise_amount?.currency
      ]
    : data &&
      data?.request_time_data_payment_plan &&
      data?.request_time_data_payment_plan[0] &&
      data?.request_time_data_payment_plan[0].promise_amount &&
      data?.request_time_data_payment_plan[0].promise_amount?.currency;
  // Header end

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let findFreq =
    data && data?.planned_frequency
      ? referenceData?.payment_plan_frequency.find(
          (e) => e.id === data?.planned_frequency
        )
      : [];

  return (
    <>
      <div className=" invoices-container invoices-header-container --payment_plan_tabel">
        {/* Body Start */}
        <div
          className="cp-tab__table-wrapper"
          style={{
            background: "#fff",
            textAlign: "center",
            display: "flex",
            // justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <div
              className="payment_title --mobl_title"
              style={{
                justifyContent: "center",
              }}
            >
              <>
                {/* BACK BUTTON STARTS */}
                <div className="payment_back_btn --changeds">
                  <NavLink
                    to={`/${hash}${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`}
                  >
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        border: "0.5px solid #cdced9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i className="mx-icon-Group-16969" />
                    </div>
                  </NavLink>
                </div>
              </>

              <div>
                <LabelLarge
                  $style={{
                    textAlign: "center",
                  }}
                  className="payment-list-org_name"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      gap: 9,
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge>Payment Plan</LabelLarge>
                  </div>
                </LabelLarge>
                <ParagraphMedium $style={{ color: "#ADADAD" }}>
                  You have an Existing Payment plan
                </ParagraphMedium>
              </div>
            </div>
          </div>
          <div
            className={
              organization && organization.industry_sector === LoanBroker
                ? "existing_payment_plan--modify"
                : "existing_payment_plan"
            }
          >
            <PaymentAmountImg />
            <div>
              <div
                className="planned_amount-table"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="monthly_pay">
                  <LabelMedium $style={{ color: "#787878" }}>
                    {findFreq && findFreq.label} Payment plan with installment
                    amount of{" "}
                    <NumericFormat
                      displayType="text"
                      value={
                        data?.request_time_data_payment_plan[0].promise_amount
                          ?.value
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(values) => (
                        <>
                          {installmentsSymbol}
                          {""}
                          {values} {}
                        </>
                      )}
                    />
                    starting from{" "}
                    {moment
                      .tz(
                        data?.request_time_data_payment_plan[0].promise_date,
                        organization.time_zone
                      )
                      .format(organization.date_format)}{" "}
                    till{" "}
                    {moment
                      .tz(
                        data?.request_time_data_payment_plan[
                          data?.request_time_data_payment_plan.length - 1
                        ].promise_date,
                        organization.time_zone
                      )
                      .format(organization.date_format)}
                  </LabelMedium>
                </div>
              </div>
              {/* Table */}
              <div className="planned_amount-table">
                <div className="tableFixHead">
                  <table>
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Installment Date</th>
                        <th>
                          Installment Amount in{" "}
                          {currencyList[
                            data &&
                              data?.request_time_data_payment_plan &&
                              data?.request_time_data_payment_plan[0] &&
                              data?.request_time_data_payment_plan[0]
                                .promise_amount &&
                              data?.request_time_data_payment_plan[0]
                                .promise_amount?.currency
                          ]
                            ? currencyList[
                                data &&
                                  data?.request_time_data_payment_plan &&
                                  data?.request_time_data_payment_plan[0] &&
                                  data?.request_time_data_payment_plan[0]
                                    .promise_amount &&
                                  data?.request_time_data_payment_plan[0]
                                    .promise_amount?.currency
                              ]
                            : data &&
                              data?.request_time_data_payment_plan &&
                              data?.request_time_data_payment_plan[0] &&
                              data?.request_time_data_payment_plan[0]
                                .promise_amount &&
                              data?.request_time_data_payment_plan[0]
                                .promise_amount?.currency}
                        </th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="paymentplan_tablebody">
                      {data?.request_time_data_payment_plan.map((e, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment
                                .tz(e.promise_date, organization.time_zone)
                                .format(organization.date_format)}
                            </td>
                            <td>
                              {
                                <NumericFormat
                                  displayType="text"
                                  value={e?.promise_amount?.value}
                                  thousandsGroupStyle={currencyGroupStyle}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={currencySeparator}
                                  decimalSeparator={currencyDecimalSeparator}
                                  renderText={(values) => (
                                    <ParagraphMedium>
                                      {values} {}
                                    </ParagraphMedium>
                                  )}
                                />
                              }
                            </td>
                            <td>
                              {/* {<Status type={e.status} />} */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <td className={`${e.status}`}></td>
                                <FormattedMessage id={e.status}>
                                  {e.status}
                                </FormattedMessage>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Mobile Table */}
            <div className="mobile_table">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div className="monthly_pay">
                  <LabelMedium $style={{ color: "#787878" }}>
                    {findFreq && findFreq.label} Payment plan with installment
                    amount of{" "}
                    <NumericFormat
                      displayType="text"
                      value={
                        data?.request_time_data_payment_plan[0].promise_amount
                          ?.value
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(values) => (
                        <>
                          {currencyList[
                            data?.request_time_data_payment_plan[0]
                              .promise_amount?.currency
                          ]
                            ? currencyList[
                                data?.request_time_data_payment_plan[0]
                                  .promise_amount?.currency
                              ]
                            : data?.request_time_data_payment_plan[0]
                                .promise_amount?.currency}{" "}
                          {values} {}
                        </>
                      )}
                    />
                    starting from{" "}
                    {moment
                      .tz(
                        data?.request_time_data_payment_plan[0].promise_date,
                        organization.time_zone
                      )
                      .format(organization.date_format)}{" "}
                    till{" "}
                    {moment
                      .tz(
                        data?.request_time_data_payment_plan[
                          data?.request_time_data_payment_plan.length - 1
                        ].promise_date,
                        organization.time_zone
                      )
                      .format(organization.date_format)}
                  </LabelMedium>
                </div>
              </div>

              <LabelLarge>
                Installments in{" "}
                {currencyList[
                  data?.request_time_data_payment_plan[0].promise_amount
                    ?.currency
                ]
                  ? currencyList[
                      data?.request_time_data_payment_plan[0].promise_amount
                        ?.currency
                    ]
                  : data?.request_time_data_payment_plan[0].promise_amount
                      ?.currency}
              </LabelLarge>
              <div>
                {data?.request_time_data_payment_plan.map((e, i) => {
                  return (
                    <div className="table_mobile">
                      <HeadingXSmall $style={{ color: "#778877" }}>
                        {i + 1}
                      </HeadingXSmall>
                      <LabelMedium>
                        {moment
                          .tz(e.promise_date, organization.time_zone)
                          .format(organization.date_format)}
                      </LabelMedium>
                      <div>
                        {
                          <NumericFormat
                            displayType="text"
                            value={e?.promise_amount?.value}
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <LabelMedium>{values}</LabelMedium>
                            )}
                          />
                        }
                      </div>
                      <div style={{ width: "80px" }}>
                        <LabelMedium className="states">
                          {" "}
                          <LabelMedium className={`${e.status}`}></LabelMedium>
                          <FormattedMessage id={e.status}>
                            {e.status}
                          </FormattedMessage>
                        </LabelMedium>
                      </div>
                    </div>
                  );
                })}
              </div>

              {data && data?.is_previous_promise_pending ? (
                <div
                  className="planned_pay"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="go_back-btn"
                    style={{ width: "224px", paddingTop: "10px" }}
                  >
                    <NavLink
                      to={`/${hash}/payments${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`}
                      state={{
                        amount:
                          data &&
                          data?.previous_pending_installation_detail &&
                          data?.previous_pending_installation_detail?.amount &&
                          data?.previous_pending_installation_detail?.amount
                            .value,
                        invoiceCurrency:
                          data &&
                          data?.previous_pending_installation_detail &&
                          data?.previous_pending_installation_detail?.amount &&
                          data?.previous_pending_installation_detail?.amount
                            ?.currency,
                      }}
                    >
                      <LabelMedium>
                        Installment amount due for Payment {symbol}{" "}
                        <NumericFormat
                          displayType="text"
                          value={
                            data?.request_time_data_payment_plan[0]
                              .promise_amount?.value
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(values) => <>{values}</>}
                        />
                      </LabelMedium>

                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        type="button"
                        kind={KIND.secondary}
                        onClick={() =>
                          dispatch(
                            paymentAmount(
                              data &&
                                data?.previous_pending_installation_detail &&
                                data?.previous_pending_installation_detail
                                  ?.amount &&
                                data?.previous_pending_installation_detail
                                  ?.amount.value
                            )
                          )
                        }
                      >
                        Pay now
                      </TextButton>
                    </NavLink>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {/* <div
                style={{
                  position: 'fixed',
                  bottom: ' 0%',
                  height: 'auto',
                  borderRadius: '10px',
                  borderTop: ' 1px solid #CDCED9',
                  width: "100%",
                  backgroundColor: default_color,

                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "14px",
                    paddingRight: "20px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <ParagraphLarge style={{ color: "#ffffff" }}>

                    </ParagraphLarge>
                    <div
                      style={{
                        height: "6px",
                        width: "6px",
                        backgroundColor: "#ffffff",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div style={{ display: "flex", gap: "3px" }}>
                    
                      <NumericFormat
                        displayType="text"
                        value={2000}
                        thousandsGroupStyle={currencyGroupStyle}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={currencySeparator}
                        decimalSeparator={currencyDecimalSeparator}
                        renderText={(values) => (
                          <>
                            <LabelLarge style={{ color: "#ffffff", fontWeight: "500" }}>
                              <sup style={{ fontSize: "9px" }}>
                                {customer?.currency}
                              </sup>{" "}
                              {values}
                            </LabelLarge>
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ width: "145px" }}>
                    <NavLink
                      to={`/${hash}/payments${showActiveUser ? `?i=${showActiveUser}` : ""
                        }`}
                    >
                      <TextButton kind={KIND.secondary} size={SIZE.compact} fullWidth>
                        Pay Now
                      </TextButton>
                    </NavLink>
                  </div>
                </div>
              </div > */}
            </div>
          </div>
          {data && data?.is_previous_promise_pending ? (
            // ||  _.get(data, "is_card_setup_complete")
            <div
              className="plannedamountpending"
              style={{
                backgroundColor: default_color,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "14px",
                  paddingRight: "20px",
                }}
              >
                {_.get(data, "is_auto_debit_payment_plan") &&
                !_.get(data, "is_card_setup_complete") ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <ParagraphLarge style={{ color: "#ffffff" }}>
                      {data &&
                        data?.previous_pending_installation_detail &&
                        data?.previous_pending_installation_detail?.count}{" "}
                      Installments{" "}
                    </ParagraphLarge>
                    <div
                      style={{
                        height: "6px",
                        width: "6px",
                        backgroundColor: "#ffffff",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <NumericFormat
                        displayType="text"
                        value={
                          data &&
                          data?.previous_pending_installation_detail &&
                          data?.previous_pending_installation_detail?.amount &&
                          data?.previous_pending_installation_detail?.amount
                            .value
                        }
                        thousandsGroupStyle={currencyGroupStyle}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={currencySeparator}
                        decimalSeparator={currencyDecimalSeparator}
                        renderText={(values) => (
                          <>
                            <LabelLarge
                              style={{ color: "#ffffff", fontWeight: "500" }}
                            >
                              {currencyList[
                                data &&
                                  data?.previous_pending_installation_detail &&
                                  data?.previous_pending_installation_detail
                                    ?.amount &&
                                  data?.previous_pending_installation_detail
                                    ?.amount?.currency
                              ]
                                ? currencyList[
                                    data &&
                                      data?.previous_pending_installation_detail &&
                                      data?.previous_pending_installation_detail
                                        ?.amount &&
                                      data?.previous_pending_installation_detail
                                        ?.amount?.currency
                                  ]
                                : data &&
                                  data?.previous_pending_installation_detail &&
                                  data?.previous_pending_installation_detail
                                    ?.amount &&
                                  data?.previous_pending_installation_detail
                                    ?.amount?.currency}{" "}
                              {values}
                            </LabelLarge>
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div style={{ width: "145px" }}>
                  {_.get(data, "is_auto_debit_payment_plan", false) &&
                  !_.get(data, "is_card_setup_complete", false) ? (
                    <TextButton
                      kind={KIND.secondary}
                      size={SIZE.compact}
                      fullWidth
                      onClick={() => {
                        window.location.replace(
                          _.get(data, "auto_debit_redirect_url.url")
                        );
                      }}
                    >
                      Set Up Auto-Debit
                    </TextButton>
                  ) : (
                    // <NavLink
                    //   to={`/${hash}/payments${
                    //     showActiveUser ? `?i=${showActiveUser}` : ""
                    //   }`}
                    //   state={{
                    //     amount:
                    //       data &&
                    //       data?.previous_pending_installation_detail &&
                    //       data?.previous_pending_installation_detail?.amount &&
                    //       data?.previous_pending_installation_detail?.amount
                    //         .value,
                    //     invoiceCurrency:
                    //       data &&
                    //       data?.previous_pending_installation_detail &&
                    //       data?.previous_pending_installation_detail?.amount &&
                    //       data?.previous_pending_installation_detail?.amount
                    //         ?.currency,
                    //   }}
                    // >
                    <TextButton
                      kind={KIND.secondary}
                      size={SIZE.compact}
                      fullWidth
                      onClick={() => {
                        dispatch(
                          paymentAmount(
                            data &&
                              data?.previous_pending_installation_detail &&
                              data?.previous_pending_installation_detail
                                ?.amount &&
                              data?.previous_pending_installation_detail?.amount
                                .value
                          )
                        );
                        navigate(
                          `/${hash}/payments${
                            showActiveUser ? `?i=${showActiveUser}` : ""
                          }`
                        );
                      }}
                    >
                      Pay Now
                    </TextButton>
                    // </NavLink>
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Body End */}
      </div>
    </>
  );
};
export default PaymentAmount;
