import { DisplayLarge, LabelLarge, LabelMedium } from "baseui/typography";
import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextBox } from "../TextBox";

const FontStyleSelector = ({ canvasRef, isText }) => {
  // onBlobGenerated = () => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   const text = isText.length ? isText : "";
  //   const fontSize = 24;

  //   // Configure canvas dimensions and style
  //   canvas.width = 400;
  //   canvas.height = 100;
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   ctx.font = `${fontSize}px Sacramento`;
  //   ctx.fillStyle = "#000";
  //   ctx.textBaseline = "middle";
  //   ctx.fillText(text, 10, canvas.height / 2);

  //   // Convert the canvas to a Blob and send it to parent
  //   canvas.toBlob((blobData) => {
  //     if (onBlobGenerated) {
  //       setBlob(blobData); // Call parent's callback with the Blob
  //     }
  //   }, "image/png");
  // };

  return (
    <div>
      <div>
        <DisplayLarge $style={{ fontFamily: "Sacramento", lineHeight: "auto" }}>
          {isText}
        </DisplayLarge>
      </div>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
    </div>
  );
};

export default FontStyleSelector;
