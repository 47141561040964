import React, { useEffect, useRef, useState } from "react";
import { KIND, TextButton } from "../TextButton";
import { ParagraphMedium } from "baseui/typography";

const DrawingBoard = ({
  canvasRef,
  contextRef,
  errorMessage,
  setErrorMessage,
}) => {
  //   const canvasRef = useRef(null);
  //   const contextRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth * 0.6; // Set canvas width (70% of window)
    canvas.height = 100; // Set canvas height
    canvas.style.border = "1px solid #cdced9";
    const context = canvas.getContext("2d");
    context.scale(1, 1);
    context.lineCap = "round";
    context.strokeStyle = "black";
    context.lineWidth = 2.5;
    contextRef.current = context;
  }, []);

  const startDrawing = (event) => {
    const { offsetX, offsetY } = getCoordinates(event);
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
    setErrorMessage(""); // Reset error message on drawing
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(event);
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  const stopDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    setErrorMessage(""); // Reset error message on clear
  };

  const isCanvasEmpty = () => {
    const canvas = canvasRef.current;
    const context = contextRef.current;
    const pixelBuffer = new Uint32Array(
      context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
    );

    // Check if any pixel is not transparent
    return !pixelBuffer.some((color) => color !== 0);
  };

  const getCoordinates = (event) => {
    const canvas = canvasRef.current;
    if (event.nativeEvent.touches) {
      const touch = event.nativeEvent.touches[0];
      const rect = canvas.getBoundingClientRect();
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    }
    return {
      offsetX: event.nativeEvent.offsetX,
      offsetY: event.nativeEvent.offsetY,
    };
  };

  const saveDrawing = () => {
    if (isCanvasEmpty()) {
      setErrorMessage("Drawing is required.");
      return;
    }

    const canvas = canvasRef.current;
    canvas.toBlob(async (blob) => {
      const formData = new FormData();
      formData.append("file", blob);
      //   await sendUploadFile.mutateAsync(formData);
    }, "image/png");
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      <ParagraphMedium>E-Sign here</ParagraphMedium>
      <div style={{ display: "flex", alignItems: "center" }}>
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
          style={{
            touchAction: "none",
          }}
        />
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "10px",
          }}
        >
          <TextButton size="mini" onClick={clearCanvas} kind={KIND.tertiary}>
            Clear
          </TextButton>
        </div>
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default DrawingBoard;
