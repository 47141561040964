import React from "react";
import ESignContentContainer from "../../containers/ESignContentContainer/ESignContentContainer";
import { useSearchParams } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import { LabelLarge } from "baseui/typography";
import { useSelector } from "react-redux";

const ESignContent = ({ refetch }) => {
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const { customer } = useSelector((e) => e?.layoutReduceData);

  let getIsLocation = getSearchParams.get("is_location");

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      suppressLocationOnMount: getIsLocation ? false : true, // Prevent auto-request on load
      userDecisionTimeout: 5000,
    });

  if (
    String(getIsLocation).toLowerCase() === "true" &&
    !coords?.latitude &&
    !coords?.longitude
  ) {
    return (
      <div className="invoices-container">
        <div className="invoices-header-container">
          <div className="invoices-header___message-conatiner">
            <div
              style={{
                height: "calc(100vh - 50px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: "1",
              }}
            >
              <LabelLarge>Allow Location Access</LabelLarge>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <ESignContentContainer refetch={refetch} coords={coords} />;
};

export default ESignContent;
