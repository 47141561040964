import { Modal } from "baseui/modal";
import React from "react";
import { KIND, SIZE, TextButton } from "../TextButton";
import { LabelMedium } from "baseui/typography";
import { useDispatch, useSelector } from "react-redux";
import { setESignModal, setESignModalStore } from "../../redux/Layout/action";
import { useNavigate, useParams } from "react-router-dom";

const ESignModal = () => {
  const { isEsign } = useSelector((s) => s.layoutReduceData);
  let dispatch = useDispatch();

  const { hash } = useParams();
  const navigate = useNavigate();

  return (
    <Modal
      onClose={() => {
        dispatch(setESignModal(false));
        dispatch(setESignModalStore(true));
      }}
      focusLock={false}
      isOpen={isEsign}
      animate
      autoFocus
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            padding: "22px 22px",
            width: "351px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      <LabelMedium>
        You have a document awaiting your e-signature. Please sign now to
        complete the process.
      </LabelMedium>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingTop: "5px",
        }}
      >
        <div style={{ width: "100%", flex: 1 }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            type="button"
            onClick={() => {
              navigate(`/${hash && hash}/esign`);
            }}
          >
            Sign now
          </TextButton>
        </div>
        {/* </NavLink> */}
        <label>or</label>
        <TextButton
          kind={KIND.tertiary}
          size={SIZE.mini}
          type="button"
          onClick={() => {
            dispatch(setESignModal(false));
            dispatch(setESignModalStore(true));
          }}
        >
          Continue with Portal
        </TextButton>
      </div>
    </Modal>
  );
};

export default ESignModal;
