import {
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
  LabelLarge,
  ParagraphSmall,
} from "baseui/typography";
import { Avatar } from "baseui/avatar";
import { IconButton } from "../../components/IconButton";
import React, { useState, useMemo, useEffect } from "react";
import MOCK_DATA from "../Invoices/MOCK_DATA.json";
import { COLUMNS } from "../Invoices/columns";
import TextButton from "../../components/TextButton/TextButton";
import { KIND, SIZE } from "baseui/button";
import CircleTick from "../../assets/img/svg/CircleTick";
import { TextArea } from "../../components/TextArea/TextArea";
import { TextBox } from "../../components/TextBox/TextBox";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import MessageHeaderSvg from "../../assets/img/svg/MessageHeaderSvg";
import { useForm, Controller } from "react-hook-form";
// import moment from "moment";
import {
  getCallBackLater,
  getCallback,
  getPromiseToPay,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { SelectBox } from "../../components/SelectBox/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import setSelectValues from "../../utils/setSelectValues";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment-timezone";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { refetachLayoutData } from "../../redux/Layout/action";
import Info from "../../assets/img/svg/Info";
import reqCall from "../../assets/img/req-call.png";
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import RequestCallBackSvg from "../../assets/img/svg/RequestCallBackSvg";
import { NumericFormat } from "react-number-format";
import _ from "lodash";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import CallBackSvg from "../../assets/img/svg/CallBackSvg";
import RequestCallSvg from "../../assets/img/svg/RequestCallSvg";
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from "libphonenumber-js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

let mm_time_zone = {
  PST8PDT: "PST",
  MST7MDT: "MST",
  CST6CDT: "CST",
  EST5EDT: "EST",
};

const RequestingCall = () => {
  const intl = useIntl();
  const [selectCard, setSelectCard] = useState(true);
  const schema = Yup.object().shape({
    prf_cb_nm: Yup.object().shape({
      value: Yup.string()
        .test("prf_cb_nm", "Invaild Phone Number", (value, ctr) => {
          if (!value || value.toString().trim() === "") return true;
          if (
            ctr &&
            ctr.parent &&
            ctr.parent &&
            ctr.parent.code &&
            ctr.parent.code[0] &&
            ctr.parent.code[0].id &&
            ctr?.parent?.value?.toString()?.length
          ) {
            let NumberValid = isValidPhoneNumber(
              `${ctr?.parent?.code[0].id}${value}`
            );
            return NumberValid;
          } else {
            return true;
          }
        })
        .nullable()
        .optional()
        .notRequired(),
    }),

    callback_date: Yup.date().required("Required"),
    callback_time: Yup.array().min(1, "Required").required("Required"),
  });

  let dispatch = useDispatch();
  let refetchData = useSelector((e) => e.layoutReduceData.refetchData);
  const layoutPhoneData = useSelector((e) => e?.layoutReduceData?.organization);
  const [iscal, setIscal] = useState();
  const { data, isLoading } = useQuery(
    [`CALLBACK-LATER-${refetchData}_${iscal}`],
    async () => {
      return await getCallBackLater({ entity: "CALLBACK" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const customizedData = useSelector((state) => state?.customizeReducer?.data);
  const referenceData = useSelector(
    (state) => state?.layoutReduceData?.referenceData
  );
  let orgData = useSelector((e) => e.layoutReduceData?.organization);
  let timeSelect = useSelector((e) => e.layoutReduceData?.time);
  let startTimeData = _.get(data, "data.doc.callback.start_time", "");
  let endTimeData = _.get(data, "data.doc.callback.end_time", "");
  let endDate = _.get(data, "data.doc.callback.callback_duration", "");
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  let activeDays = _.get(data, "data.doc.callback.callback_days", []);

  let holidays = _.get(data, "data.doc.holidays", []);
  let customizeUIData = useSelector((s) => s?.customizeReducer);

  const { default_color } = useSelector((s) => s.layoutReduceData);
  let callBackData = useSelector((e) => e.layoutReduceData?.callBackData);

  let splitData = endDate && endDate?.split("_");

  let findStartTime = timeSelect?.findIndex(({ id }) => id === startTimeData);
  let findEndTime = timeSelect?.findIndex(({ id }) => id === endTimeData);

  let finelValue = timeSelect?.slice(findStartTime, findEndTime + 1);
  const location = useLocation();

  const { hash } = useParams();

  const requestCall = useMutation((data) => getCallback(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;

        // if (typeof data.error === "object") {
        //   errorData = Object.keys(data.error).map(
        //     (e) => `${e}: ${data.error[e]}`
        //   );
        //   errorData = errorData.toString();
        // }
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      toast.success(data.data.message);
      dispatch(refetachLayoutData());
      reset();
      setIscal(iscal + 1);
    },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    if (orgData && orgData.phone && orgData.phone.code) {
      setValue("prf_cb_nm.code", setSelectValues(orgData.phone.code));
    }
  }, [orgData.phone.code]);

  // moment(data.follow_up_date).utc().valueOf();

  let callBackDataValied = false;

  if (callBackData) {
    callBackDataValied = true;
  }

  let findTimeValue = data?.data?.doc?.callback_data?.callback_time
    ? timeSelect &&
      timeSelect.find(
        (e) => e.id == data?.data?.doc?.callback_data?.callback_time
      )
    : [];

  let watchInput = watch(["callback_date", "callback_time"]);

  useEffect(() => {
    if (data && data?.data && data?.data?.doc?.is_callback_pending) {
      setSelectCard(false);
    }
  }, [data?.data]);

  const onSubmit = async (data) => {
    let values = {
      callback_date: moment
        .tz(
          `${data.callback_date.getFullYear()}-${
            data.callback_date.getMonth() + 1
          }-${data.callback_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .startOf("day")
        .utc()
        .valueOf(),
      callback_time: getSelectValues(data.callback_time),
      ...(data?.prf_cb_nm?.value?.length
        ? {
            prf_cb_nm: {
              code: getSelectValues(data.prf_cb_nm.code),
              value: data.prf_cb_nm.value,
            },
          }
        : {}),
      ...(data &&
      data.comment &&
      data.comment.length &&
      data &&
      data.prf_cb_nm &&
      data.prf_cb_nm.value &&
      data.prf_cb_nm.value.length
        ? {
            comment: `${data.comment} - Preferred Call Back Number: ${
              data && data?.prf_cb_nm?.code?.[0]?.id
            } ${data && data?.prf_cb_nm?.value}`,
          }
        : data?.prf_cb_nm?.value?.length
        ? {
            comment: `Preferred Call Back Number: ${
              data && data?.prf_cb_nm?.code?.[0]?.id
            } ${data && data.prf_cb_nm && data.prf_cb_nm.value}`,
          }
        : data && data.comment && data.comment.length > 0
        ? {
            comment: data.comment,
          }
        : {}),
    };
    await requestCall.mutateAsync(values);
  };

  let dateFormat = useSelector(
    (e) =>
      e && e.layoutReduceData && e.layoutReduceData?.organization.date_format
  );
  let localDate = utcTimstampToLocalDate(callBackData?.callback_date);

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);
  // const { total_outstanding_amount = 0 } = customer;

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let totalAmtLength = total_outstanding_amount;
  const symbol = currencyList[currency] ? currencyList[currency] : currency;
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;

  const useFormattedPhoneNumber = (customizedData, layoutPhoneData) => {
    return useMemo(() => {
      const rawNumber = _.get(customizedData, "cl_conf", "");
      let ValidPhoneNumberFormat = parsePhoneNumberFromString(rawNumber);

      if (!ValidPhoneNumberFormat && _.get(layoutPhoneData, "phone.code", "")) {
        const phoneCode = _.get(layoutPhoneData, "phone.code", "");
        ValidPhoneNumberFormat = parsePhoneNumberFromString(
          `${phoneCode}${rawNumber}`
        );
      }

      if (ValidPhoneNumberFormat) {
        return ValidPhoneNumberFormat.formatInternational();
      }

      return rawNumber || "-";
    }, [customizedData, layoutPhoneData]);
  };
  const formattedNumber = useFormattedPhoneNumber(
    customizedData,
    layoutPhoneData
  );

  const formatNumber = (formattedNumber) => {
    const firstSpaceIndex = formattedNumber.indexOf(" ");

    if (firstSpaceIndex === -1) return formattedNumber;

    const firstPart = formattedNumber.substring(0, firstSpaceIndex + 1);
    const restPart = formattedNumber
      .substring(firstSpaceIndex + 1)
      .replace(/ /g, "-");

    return firstPart + restPart;
  };

  return (
    <div className="invoices-container">
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            <div className="sidebar-top">
              <NavLink
                to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {widths <= 500 ? (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        size="scale1200"
                      />
                    ) : organizationData &&
                      organizationData.customer_portal_ui &&
                      organizationData.customer_portal_ui.logo ? (
                      <div
                        style={{
                          maxWidth: `95px`,
                          minWidth: `18px`,
                          maxHeight: `48px`,
                        }}
                      >
                        <img
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          alt="customer_Logo"
                          className="customer_portal_logo"
                        />
                      </div>
                    ) : (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                              objectFit: "contain",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                              objectFit: "contain",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                              width: "100px",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        // size="scale1200"
                      />
                    )}

                    <div className="org-name">
                      <div>
                        <div className="org_name" $style={{ color: "#333860" }}>
                          {organizationData?.name}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            color: "#787878",
                            display: "flex",
                            gap: "2px",
                            // hap: "10px",
                            flexDirection: `${
                              totalAmtLength &&
                              totalAmtLength.toString().length > 15
                                ? "column"
                                : "row"
                            }`,
                          }}
                        >
                          <div className="totl-ots-mob"> Total O/S: </div>
                          <div className="totl_ots-web">
                            {" "}
                            Total Outstanding:{" "}
                          </div>
                          {/* <>Total Outstanding</> */}
                          <NumericFormat
                            displayType="text"
                            value={
                              total_outstanding_amount
                                ? total_outstanding_amount
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <div style={{ paddingLeft: "3px" }}>
                                {symbol} {values}
                              </div>
                            )}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>

          {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
          <div className="invoices-header___nav-buttons">
            {accessValue?.is_mini_miranda_configured && (
              <div>
                <div
                  class="button-tilt"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <DebtorRight size={18} />
                    <span class="label-hidden">Consumer Rights</span>
                  </IconButton>
                </div>
              </div>
            )}
            <div>
              <div
                class="button-tilt email-btn"
                onClick={() => {
                  sendEmail();
                }}
              >
                <IconButton kind={KIND.tertiary}>
                  <i className="mx-icon-Vector-2" />
                  <span class="label-hidden">Email us</span>
                </IconButton>
              </div>
            </div>

            {accessValue && accessValue.is_callback && (
              <div>
                <NavLink
                  to={`/${hash}/requesting-calls${
                    showActiveUser ? `?i=${showActiveUser}` : ""
                  }`}
                >
                  <div class="button-tilt">
                    <IconButton kind={KIND.tertiary}>
                      <i className="mx-icon-call-back-2" />
                      <span class="label-hidden">Call Back Request</span>
                    </IconButton>
                  </div>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="back__btn-comp">
        <>
          {selectCard ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="rq__call__form__wraper"
            >
              {/* <div className="rpt-svg-header"> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "",
                }}
              >
                <div className="--request-call">
                  <NavLink to={`/${hash}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        border: "0.5px solid #cdced9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i className="mx-icon-Group-16969" />
                    </div>
                  </NavLink>
                </div>
                <div style={{ textAlign: "center" }}>
                  <HeadingXLarge>Request Call Back</HeadingXLarge>
                  <ParagraphMedium $style={{ color: "#ADADAD" }}>
                    Choose You Preferred Call Back Date and Time
                  </ParagraphMedium>
                </div>
              </div>
              {/* </div> */}

              <div style={{ height: "90%", overflow: "scroll" }}>
                <div style={{ padding: "6px 20px" }}>
                  <div
                    className="req-time__input-wrap"
                    style={{ flexDirection: "column", gap: "0px" }}
                  >
                    <div style={{ flex: "1" }}>
                      <Controller
                        name="callback_date"
                        control={control}
                        render={({ field }) => (
                          <DateRangeSelect
                            {...field}
                            size={SIZE.mini}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            value={field.value}
                            onChange={(e) => field.onChange(e.date)}
                            // disabled={callBackDataValied}
                            minDate={new Date()}
                            maxDate={
                              endDate != "no_validation" &&
                              moment()
                                .add(
                                  splitData && splitData[1],
                                  splitData && splitData[0]
                                )
                                .toDate()
                            }
                            label={intl.formatMessage({
                              id: "preferred_call_back_date",
                            })}
                            filterDate={(date) => {
                              if (endDate != "no_validation") {
                                let callbackdays = activeDays;
                                let days = {
                                  sunday: 0,
                                  monday: 1,
                                  tuesday: 2,
                                  wednesday: 3,
                                  thursday: 4,
                                  friday: 5,
                                  saturday: 6,
                                };

                                let getHDate = holidays;
                                let pickerDate = moment(date)
                                  .tz(timeZone)
                                  .startOf("day")
                                  .toISOString();
                                let dateFull = getHDate?.map((e) =>
                                  moment(e)
                                    .tz(timeZone)
                                    .startOf("day")
                                    .toISOString()
                                );

                                let allowedDays = callbackdays?.map(
                                  (e) => days[e]
                                );
                                if (
                                  allowedDays?.includes(date.getDay()) &&
                                  !dateFull?.includes(pickerDate)
                                ) {
                                  return true;
                                }
                                return false;
                              } else {
                                let getHDate = holidays;
                                let pickerDate = moment(date)
                                  .tz(timeZone)
                                  .startOf("day")
                                  .toISOString();
                                let dateFull = getHDate?.map((e) =>
                                  moment(e)
                                    .tz(timeZone)
                                    .startOf("day")
                                    .toISOString()
                                );
                                if (!dateFull?.includes(pickerDate)) {
                                  return true;
                                }
                                return false;
                              }
                            }}
                            // filterDate={(date) => {
                            //   let getHDate = holidays;
                            //   let pickerDate = moment(date)
                            //     .tz(timeZone)
                            //     .startOf("day")
                            //     .date();
                            //   let dateFull = getHDate.map((e) =>
                            //     moment(e).date()
                            //   );
                            //   if (dateFull?.includes(pickerDate)) {
                            //     return true;
                            //   }
                            //   return false;
                            // }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <Controller
                        name="callback_time"
                        control={control}
                        render={({ field }) => (
                          <SelectBox
                            clearable={false}
                            style={{ marginTop: "0px" }}
                            {...field}
                            size={SIZE.mini}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "preferred_call_back_time",
                            })}
                            placeholder={intl.formatMessage({
                              id: "preferred_call_back_time",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={finelValue}
                            // disabled={callBackDataValied}
                          />
                        )}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "10px",
                          marginTop: "-10px",
                        }}
                      >
                        <Info />
                        <LabelSmall
                          $style={{
                            color: "#ADADAD",
                            fontSize: "10px",
                            fontWeight: "400",
                          }}
                        >
                          The Time slot is in{" "}
                          {mm_time_zone && mm_time_zone[timeZone]
                            ? mm_time_zone[timeZone]
                            : timeZone}
                        </LabelSmall>
                      </div>
                    </div>
                    <div style={{ flex: "1", display: "flex", gap: "5px" }}>
                      <div style={{ width: "100px" }}>
                        <Controller
                          name="prf_cb_nm.code"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              style={{ marginTop: "0px" }}
                              {...field}
                              size={SIZE.mini}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "code",
                              })}
                              placeholder={intl.formatMessage({
                                id: "code",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={
                                referenceData &&
                                referenceData["contact_phone_code"] &&
                                referenceData["contact_phone_code"]
                              }
                            />
                          )}
                        />
                      </div>
                      <Controller
                        name="prf_cb_nm.value"
                        control={control}
                        render={({ field }) => (
                          <TextBox
                            {...field}
                            name={field.name}
                            value={field.value}
                            size={SIZE.mini}
                            type="number"
                            error={
                              errors["prf_cb_nm"] &&
                              errors["prf_cb_nm"].value &&
                              errors["prf_cb_nm"].value.message
                            }
                            label={intl.formatMessage({
                              id: "preferred_call_back_number",
                            })}
                            placeholder={intl.formatMessage({
                              id: "preferred_call_back_number",
                            })}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        {...field}
                        fullWidth
                        size={SIZE.mini}
                        requiredAstric={true}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "your_message_or_note",
                        })}
                        placeholder={intl.formatMessage({
                          id: "your_message_or_note1",
                        })}
                        value={field.value}
                        // disabled={}
                      />
                    )}
                  />
                  <div className="time-slot">
                    <LabelSmall>
                      Schedule Call back on{" "}
                      {watchInput && watchInput[0] ? (
                        moment(watchInput[0]).format(dateFormat)
                      ) : (
                        <>- </>
                      )}{" "}
                      at{" "}
                      {watchInput && watchInput[1] && watchInput[1][0] ? (
                        watchInput[1][0].label
                      ) : (
                        <>-</>
                      )}
                    </LabelSmall>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "150px" }}>
                      <TextButton
                        fullWidth
                        size={SIZE.mini}
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Schedule Call Back
                      </TextButton>
                    </div>
                  </div>
                  {_.get(customizedData, "cl_conf", "") && (
                    <>
                      <div className="container-contact-customize">
                        <div className="border-contact-customize" />
                        <span className="content-contact-customize">or</span>
                        <div className="border-contact-customize" />
                      </div>

                      <div className="request-call-contact-us-container">
                        <a href={`tel:${_.get(customizedData, "cl_conf", "")}`}>
                          <div
                            className="request-call-contact-us-sub-container"
                            style={{ outline: "none" }}
                          >
                            <div
                              className="request-call-contact-us-svg"
                              style={{ backgroundColor: default_color }}
                            >
                              {/* <IconButton kind={KIND.tertiary}> */}
                              <RequestCallSvg />
                              {/* </IconButton> */}
                            </div>
                            <div className="request-call-contact-us-content">
                              <LabelSmall
                                $style={{
                                  textAlign: "center",
                                  marginRight: "15px",
                                  color: "#787878",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                }}
                              >
                                CONTACT US
                              </LabelSmall>
                              <LabelMedium
                                $style={{
                                  textAlign: "center",
                                  // color: "#787878",
                                  // fontSize: "10px",
                                  // fontWeight: "400",
                                }}
                              >
                                {formattedNumber
                                  ? formatNumber(formattedNumber)
                                  : "-"}
                                {/* {_.get(customizedData, "cl_conf", "")} */}
                              </LabelMedium>
                            </div>
                          </div>
                        </a>

                        <LabelMedium
                          $style={{
                            textAlign: "center",
                            marginTop: "5px",
                            // color: "#787878",
                            // fontSize: "10px",
                            fontWeight: "400",
                          }}
                        >
                          {_.get(customizedData, "wh_conf", "")}
                        </LabelMedium>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          ) : (
            <div className="rq__call__form__wraper --modifier">
              <div
                className="req__show_d"
                style={{ flexDirection: "column", gap: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "",
                  }}
                >
                  <div className="--request-call none">
                    <NavLink to={`/${hash}`}>
                      <div
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "50%",
                          border: "0.5px solid #cdced9",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i className="mx-icon-Group-16969" />
                      </div>
                    </NavLink>
                  </div>
                  <div className="rpt-svg-header">
                    <HeadingXLarge>Call Back Details</HeadingXLarge>
                    <ParagraphMedium $style={{ color: "#ADADAD" }}>
                      Find your call Back Details Below
                    </ParagraphMedium>
                  </div>
                </div>

                <div>
                  {/* <img src={reqCall} /> */}
                  <RequestCallBackSvg />
                </div>
                <div className="--promise_details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="rpt-svg-bottom-header"
                  >
                    <HeadingXLarge>
                      {" "}
                      {/* <span className="invoices-title__color"> */}
                      Call Back Details
                    </HeadingXLarge>
                    <ParagraphMedium $style={{ color: "#ADADAD" }}>
                      Find your call Back Details Below
                    </ParagraphMedium>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 20px",
                  }}
                  // className="rpt_val"
                >
                  <div style={{ display: "flex", gap: "20px" }}>
                    <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                      Call Date :
                    </ParagraphMedium>
                    <ParagraphMedium>
                      {data &&
                      data.data?.doc &&
                      data.data.doc?.callback_data &&
                      data.data?.doc?.callback_data?.callback_date
                        ? moment
                            .tz(
                              data &&
                                data?.data &&
                                data.data?.doc &&
                                data.data?.doc?.callback_data &&
                                data.data?.doc?.callback_data?.callback_date,
                              timeZone
                            )
                            .format(dateFormat)
                        : "-"}
                    </ParagraphMedium>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                      Call Time :
                    </ParagraphMedium>
                    <ParagraphMedium>
                      {findTimeValue && findTimeValue.label
                        ? findTimeValue.label
                        : "-"}
                    </ParagraphMedium>
                  </div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    <ParagraphMedium style={{ whiteSpace: "noWrap" }}>
                      Message:
                    </ParagraphMedium>
                    <ParagraphMedium>
                      {_.get(data, "data.doc.callback_data.comment", "")
                        ? _.get(data, "data.doc.callback_data.comment", "")
                        : `You have scheduled call back with ${orgData?.name} on
                above mentioned date and time.`}{" "}
                    </ParagraphMedium>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="cbd-back">
                    <TextButton
                      kind={KIND.secondary}
                      size={SIZE.compact}
                      startEnhancer={
                        <i className="mx-icon-Group-16179 font-icon-color" />
                      }
                    >
                      <NavLink
                        to={`/${hash}${
                          showActiveUser ? `?i=${showActiveUser}` : ""
                        }`}
                      >
                        Go Back
                      </NavLink>
                    </TextButton>
                  </div>

                  <TextButton
                    onClick={() => setSelectCard(true)}
                    kind={KIND.primary}
                    size={SIZE.compact}
                  >
                    Reschedule
                  </TextButton>
                </div>
              </div>
            </div>
          )}
        </>
        {/* COMPONENTS ENDS */}
      </div>
    </div>
  );
};

export default RequestingCall;
