import React, { useEffect } from "react";
import "./assets/css/table.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { IntlProvider } from "react-intl";
import { Client as Styletron } from "styletron-engine-atomic";
import AppLocale from "./lang";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "baseui";
import CombineRouter from "./router/combineRouters/CombineRouter";
import { Provider, useSelector } from "react-redux";
// import store from "./redux/store";
import "./assets/scss/global.scss";
import colors from "./assets/scss/_variables.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./providers/AuthProvider";
// import { ModalProvider } from "./providers/ModalProvider";
import { RBACProvider } from "./providers/RBACProvider";
import store, { persistor } from "./redux/store";
import lbLang from "./lang/enteries/en-INSE225";

const engine = new Styletron();

const queryClient = new QueryClient();

function PortalTheme() {
  const defaultLocale = "en";
  const { default_color, organization } = useSelector(
    (s) => s.layoutReduceData
  );

  let locale =
    navigator.language && navigator?.language?.split("-")[0]
      ? navigator?.language?.split("-")[0]
      : defaultLocale;

  let currentLocale =
    (AppLocale &&
      AppLocale[locale] &&
      AppLocale[locale][organization?.industry_sector]) ||
    (AppLocale && AppLocale[defaultLocale] && AppLocale[defaultLocale]["US"]);

  let PRIMARY_COLOR = default_color;
  const SECONDARY_COLOR = colors.SECONDARY_COLOR;
  const SURFACE_COLOR = colors.SURFACE_COLOR;
  const BACKGROUND_COLOR = colors.BACKGROUND_COLOR;

  const customTheme = createTheme(
    { primary: PRIMARY_COLOR, primaryFontFamily: "Roboto" },
    {
      colors: {
        buttonIconPrimaryFill: BACKGROUND_COLOR,
        buttonIconPrimaryHover: PRIMARY_COLOR,
        buttonTertiaryHover: SURFACE_COLOR,
        buttonPrimaryfill: PRIMARY_COLOR,
        // buttonPrimaryHover: TEXT_BACKGROUND_COLOR,
        // buttonPrimaryFill: PRIMARY_COLOR,
        // buttonPrimaryHover: "#0348C0",
        buttonPrimaryHover: "#0348C0",
        buttonTertiaryFill: "transparent",
        buttonTertiaryHover: "#F0F3F8",
        buttonTertiaryText: "#323232",
        buttonTertiaryTextHover: PRIMARY_COLOR,
        buttonSecondaryFill: "#FFFFFF",
        buttonSecondaryHover: "#E2E2E2",
        tooltipBackground: SECONDARY_COLOR,
        tooltipText: "#FFFFFF",
        contentPrimary: "#333860",
        contentSecondary: "#495281",
        inputBorderColor: "#cdced9",
        inputBorder: "#CDCED9",
        customMxfi: "#000000",
        buttonTextColor: "#ffffff",
        // buttonPraveen: "#000000",
      },
      borders: { radius: "5px", radius200: "5px", radius300: "8px" },
      typography: {
        ParagraphSmall: {
          fontSize: "13px",
          fontWeight: "normal",
        },
        ParagraphMedium: {
          fontSize: "13px",
          fontWeight: "400",
        },
        ParagraphLarge: {
          fontSize: "16px",
          fontWeight: "400",
        },
        HeadingXLarge: {
          fontSize: "20px",
          fontWeight: "700",
        },
        HeadingMedium: {
          fontSize: "34px",
          fontWeight: "900",
        },
        LabelSmall: {
          fontSize: "13px",
          fontWeight: "700",
        },
        LabelMedium: {
          fontSize: "13px",
          fontWeight: "700",
        },
        LabelLarge: {
          fontSize: "16px",
          fontWeight: "700",
        },
        LabelXSmall: {
          fontSize: "12px",
          fontWeight: "700",
        },
        HeadingXSmall: {
          fontSize: "12px",
          fontWeight: "400",
        },
        DisplayLarge: {
          fontSize: "25px",
          fontWeight: "700",
        },
      },
      // ParagraphSmall:{fontSize:"13px"}
    }
  );
  return (
    <IntlProvider locale={locale} messages={currentLocale.messages}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={customTheme}>
          <QueryClientProvider client={queryClient}>
            <RBACProvider permissions={["CREATE_VIEW", "DELETE_VIEW"]}>
              {/* <ModalProvider> */}
              <AuthProvider>
                <CombineRouter />
              </AuthProvider>
              {/* </ModalProvider> */}
            </RBACProvider>
          </QueryClientProvider>
        </BaseProvider>
      </StyletronProvider>
    </IntlProvider>
  );
}

function App() {
  // const { default_color } = useSelector((s) => s.layoutReduceData);
  console.warn = () => {};
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("AuthorizationToken");
      localStorage.removeItem("hC");
      localStorage.removeItem("rT");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        {/* <IntlProvider
            locale={currentLocale.locale}
            messages={currentLocale.messages}
          > */}
        <PortalTheme />
        {/* </IntlProvider> */}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
