import React from "react";
import Invoices from "../../containers/Invoices/Invoices";
import Layout from "../Layout/Layout";

const InvoiceView = ({ setFooter, isFooter, refetch }) => {
  return (
    // <Layout>
    <Invoices setFooter={setFooter} isFooter={isFooter} refetch={refetch} />
    // </Layout>
  );
};

export default InvoiceView;
