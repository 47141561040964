import { HeadingXLarge } from "baseui/typography";
import { Avatar } from "baseui/avatar";
import { IconButton } from "../../components/IconButton";
import React, { useState, useMemo, useEffect } from "react";
import TextButton from "../../components/TextButton/TextButton";
import { KIND, SIZE } from "baseui/button";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
// import moment from "moment";
import {
  checkAuthorizsed,
  getCallBackLater,
} from "../../services/customerPortal";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import setSelectValues from "../../utils/setSelectValues";
import moment from "moment-timezone";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { NumericFormat } from "react-number-format";
import _ from "lodash";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { setDebtorRightsModal } from "../../redux/Layout/action";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import parsePhoneNumberFromString from "libphonenumber-js";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthorizationDownloadSvg from "../../assets/img/svg/AuthorizeDownloadSvg";
import InputComponent from "../AuthorizeDownload/InputComponent";

const ESignAuthContainer = () => {
  const intl = useIntl();

  const { id } = useParams();

  const {
    document_authorize_configurations,
    refetchData,
    organization,
    time,
    customer,
    default_color,
  } = useSelector((e) => e.layoutReduceData);

  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );

  const yupValidationSchema = (documentAuthorizeData) => {
    const schemaObject = {};

    documentAuthorizeData.forEach((item) => {
      switch (item.ft) {
        case "NUM":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.number().typeError("Required").min(1, "Enter Valid Number");
          break;
        case "STR":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.string().required("This field is required");
          break;
        case "DT":
          schemaObject[`${item.fm === "CON" ? "con" : "cus"}_${item.fk}`] =
            Yup.date().typeError("Required").required("Date is required");
          break;
        // Add additional cases for other field types if needed
        default:
          break;
      }
    });

    return Yup.object().shape(schemaObject);
  };

  const validationSchema = yupValidationSchema(
    document_authorize_configurations
  );

  let dispatch = useDispatch();

  const { data, isLoading } = useQuery(
    [`CALLBACK-LATER-${refetchData}`],
    async () => {
      return await getCallBackLater({ entity: "CALLBACK" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  const navigate = useNavigate();

  const { hash } = useParams();

  const authorizeCall = useMutation((data) => checkAuthorizsed(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      if (data.data.doc.expired_at < Date.now()) {
      }
      localStorage.setItem("AuthorizationToken", data.data.doc.token);
      toast.success(data.data.message);
      if (customer && customer.is_location) {
        navigate(
          `/${hash && hash}/esign/${customer?.e_sign_id}?is_location=${
            customer.is_location
          }&token=${data?.data?.doc?.token}`
        );
      } else {
        navigate(
          `/${hash && hash}/esign/${customer?.e_sign_id}?token=${
            data?.data?.doc?.token
          }`
        );
      }
    },
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    const convertToTimestamp = (value) => {
      if (value instanceof Date) {
        return moment
          .tz(
            `${value ? value.getFullYear() : ""}-${
              value ? value.getMonth() + 1 : ""
            }-${value ? value.getDate() : ""}`,
            "YYYY-MM-DD",
            timeZone
          )
          .startOf("day")
          .utc()
          .valueOf();
      } else if (typeof value === "number") {
        return value.toString();
      }
      return value;
    };
    for (const key in data) {
      if (data[key] instanceof Date) {
        data[key] = convertToTimestamp(data[key]);
      } else if (typeof data[key] === "object" && !Array.isArray(data[key])) {
        for (const objKey in data[key]) {
          // Convert each value in the nested object
          data[`${key}.${objKey}`] = convertToTimestamp(data[key][objKey]);
        }
        delete data[key];
      } else {
        data[key] = convertToTimestamp(data[key]);
      }
    }
    // Make the API call with the modified data
    await authorizeCall.mutateAsync(data);
  };

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }
  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const {
    customer: { total_outstanding_amount = 0, currency = "" },
  } = useSelector((s) => s.layoutReduceData);
  // const { total_outstanding_amount = 0 } = customer;

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let totalAmtLength = total_outstanding_amount;
  const symbol = currencyList[currency] ? currencyList[currency] : currency;
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let showActiveUser = addSearchParams.get("i");
  const widths = window.innerWidth;

  return (
    <div className="invoices-container">
      <div className="invoices-header-container">
        <div className="invoices-header___message-conatiner">
          <div>
            <div className="sidebar-top">
              <NavLink
                to={`/${hash}${showActiveUser ? `?i=${showActiveUser}` : ""}`}
              >
                <div
                  style={{
                    textAlign: "left",
                    width: "100%",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {widths <= 500 ? (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        size="scale1200"
                      />
                    ) : organizationData &&
                      organizationData.customer_portal_ui &&
                      organizationData.customer_portal_ui.logo ? (
                      <div
                        style={{
                          maxWidth: `95px`,
                          minWidth: `18px`,
                          maxHeight: `48px`,
                        }}
                      >
                        <img
                          src={
                            organizationData &&
                            organizationData.customer_portal_ui &&
                            organizationData.customer_portal_ui.logo
                              ? organizationData.customer_portal_ui.logo
                              : null
                          }
                          alt="customer_Logo"
                          className="customer_portal_logo"
                        />
                      </div>
                    ) : (
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                            }),
                          },
                          Root: {
                            style: ({ $theme }) => ({
                              borderTopLeftRadius: $theme.borders.radius100,
                              borderTopRightRadius: $theme.borders.radius100,
                              borderBottomRightRadius: $theme.borders.radius100,
                              borderBottomLeftRadius: $theme.borders.radius100,
                              backgroundColor: "#F8F8FB",
                              width: "48px",
                              objectFit: "contain",
                            }),
                          },
                          Initials: {
                            style: () => ({
                              color: default_color,
                              fontSize: "14px",
                              fontWeight: "700",
                              objectFit: "contain",
                            }),
                          },
                          Avatar: {
                            style: () => ({
                              objectFit: "contain",
                              width: "100px",
                            }),
                          },
                        }}
                        name={organizationData?.name}
                        src={
                          organizationData &&
                          organizationData.customer_portal_ui &&
                          organizationData.customer_portal_ui.logo
                            ? organizationData.customer_portal_ui.logo
                            : null
                        }
                        // size="scale1200"
                      />
                    )}

                    <div className="org-name">
                      <div>
                        <div className="org_name" $style={{ color: "#333860" }}>
                          {organizationData?.name}
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            color: "#787878",
                            display: "flex",
                            gap: "2px",
                            // hap: "10px",
                            flexDirection: `${
                              totalAmtLength &&
                              totalAmtLength.toString().length > 15
                                ? "column"
                                : "row"
                            }`,
                          }}
                        >
                          <div className="totl-ots-mob"> Total O/S: </div>
                          <div className="totl_ots-web">
                            {" "}
                            Total Outstanding:{" "}
                          </div>
                          {/* <>Total Outstanding</> */}
                          <NumericFormat
                            displayType="text"
                            value={
                              total_outstanding_amount
                                ? total_outstanding_amount
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(values) => (
                              <div style={{ paddingLeft: "3px" }}>
                                {symbol} {values}
                              </div>
                            )}
                          />
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>

          {/* <div>
            <Link to="/messages">
            <MessageHeaderSvg />
            </Link>
          </div> */}
          {/* <div className="invoices-header___nav-buttons">
            {accessValue?.is_mini_miranda_configured && (
              <div>
                <div
                  class="button-tilt"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <IconButton kind={KIND.tertiary}>
                    <DebtorRight size={18} />
                    <span class="label-hidden">Consumer Rights</span>
                  </IconButton>
                </div>
              </div>
            )}
            <div>
              <div
                class="button-tilt email-btn"
                onClick={() => {
                  sendEmail();
                }}
              >
                <IconButton kind={KIND.tertiary}>
                  <i className="mx-icon-Vector-2" />
                  <span class="label-hidden">Email us</span>
                </IconButton>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="back__btn-comp">
        <div className="back__btn-comp">
          {/* COMPONENTS STARTS */}
          <>
            {
              <>
                <div className="rq__call__form__wraper --modifier">
                  <div
                    className="req__show_d"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "",
                      }}
                    >
                      <div className="--request-call">
                        <NavLink to={`/${hash}`}>
                          <div
                            style={{
                              height: "30px",
                              width: "30px",
                              borderRadius: "50%",
                              border: "0.5px solid #cdced9",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i className="mx-icon-Group-16969" />
                          </div>
                        </NavLink>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <HeadingXLarge>Authorization Required</HeadingXLarge>
                      </div>
                    </div>
                    <div>
                      <AuthorizationDownloadSvg />
                    </div>
                    <div>
                      <div>
                        {/* <DynamicForm formConfig={config} onSubmit={onSubmit} hash="yourHash" /> */}
                        <form onSubmit={handleSubmit(onSubmit)}>
                          {document_authorize_configurations.map((item) => (
                            <Controller
                              key={`${_.get(item, "fm", "").toLowerCase()}_${
                                item.fk
                              }`}
                              control={control}
                              name={`${_.get(item, "fm", "").toLowerCase()}_${
                                item.fk
                              }`}
                              render={({ field }) => {
                                return (
                                  <div
                                    style={{
                                      left: 0,
                                      top: 0,
                                      width: "250px",
                                    }}
                                  >
                                    <InputComponent
                                      {...field}
                                      item={item}
                                      value={field.value}
                                      onChange={(e) => {
                                        const newValue =
                                          item.ft === "DT"
                                            ? e.date
                                            : e.target.value;
                                        field.onChange(newValue);
                                      }}
                                      label={item.lb}
                                      error={
                                        errors[field.name] &&
                                        errors[field.name].message
                                      }
                                    />
                                  </div>
                                );
                              }}
                            />
                          ))}

                          {/* Next and Cancel Buttons */}
                          <div
                            className="frame"
                            style={{
                              alignItems: "flex-start",
                              display: "inline-flex",
                              gap: "20px",
                              left: "50px",
                              position: "relative",
                            }}
                          >
                            <NavLink to={`/${hash}`}>
                              <TextButton
                                type="button"
                                kind="tertiary"
                                size="compact"
                                fullWidth
                              >
                                Cancel
                              </TextButton>
                            </NavLink>

                            <TextButton
                              type="submit"
                              kind="primary"
                              size="compact"
                              fullWidth
                            >
                              Next
                            </TextButton>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </>
        </div>
      </div>
    </div>
  );
};

export default ESignAuthContainer;
