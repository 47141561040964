import React from "react";
import CustomizeUi from "../../containers/CustomizeUi/CustomizeUi";
import Layout from "../Layout/Layout";

const CustomizeUiView = ({ refetch }) => {
  return (
    // <Layout>
    <CustomizeUi refetch={refetch} />
    // </Layout>
  );
};

export default CustomizeUiView;
