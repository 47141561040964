import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import "./sideBar.css";
import CreditNoteSvg from "../../assets/img/svg/CreditNoteSvg";
import InvoicesSvg from "../../assets/img/svg/InvoicesSvg";
import MessagesSvg from "../../assets/img/svg/MessagesSvg";
import PaymentsSvg from "../../assets/img/svg/PaymentsSvg";
import RequestingCallSvg from "../../assets/img/svg/RequestingCallSvg";
import RequestingTimeSvg from "../../assets/img/svg/RequestingTimeSvg";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import {
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  LabelXSmall,
  ParagraphLarge,
} from "baseui/typography";
import More from "../../assets/img/svg/More";
import { useQuery } from "react-query";
import { getLayout } from "../../services/customerPortal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLayoutData } from "../../redux/Layout/action";
// import { getLayoutData } from "../../redux/Layout/action";
import { Avatar } from "baseui/avatar";
import powerBy from "./pw-by.png";
import { LoanBroker } from "../../constants/IndustrialSector";

function IconCompo({ Icon, path, name, secondName }) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        isActive ? "icon-parent hoverclass" : "icon-parent"
      }
    >
      <div className="icon-child">
        <Icon />
        <LabelMedium $style={{ color: "#6A7187" }} className="icon">
          {name}
        </LabelMedium>
      </div>
    </NavLink>
  );
}

const SidebarMobileView = (props) => {
  // let { hash } = useParams();
  const dispatch = useDispatch();
  const web = process.env.REACT_APP_WEB_LINK;

  const location = useLocation();

  const hash = location.pathname.split("/")[1];

  const { data } = useQuery("LAYOUT_DATA", async () => {
    return await getLayout();
  });
  const {
    default_nav_color,
    organization,
    customer,
    tabMotion,
    referenceData,
  } = useSelector((s) => s.layoutReduceData);

  return (
    <>
      <div className="sidebar-wrapper mobile-view__sidebar">
        <div className="mobile-view__footer">
          <div className="footer-wraper__text">
            {organization.industry_sector == LoanBroker ? (
              ""
            ) : (
              <div className="sidebar-footer__pw-by">
                <div
                  className="powered-by"
                  style={{
                    color: "#5B5B74",
                    // paddingBottom: "4px",
                    // lineHeight: "1px",
                  }}
                >
                  Powered by
                </div>
                <HeadingXSmall
                  $style={{ width: "50px", lineHeight: "15px" }}
                  className="logo-max"
                >
                  {" "}
                  <a href={web} target="_blank" className="credit_href--web">
                    <img src={powerBy} />
                  </a>
                </HeadingXSmall>
              </div>
            )}
          </div>
        </div>
        <div className="sidebar-middle">
          {data && data.data && data.data.doc
            ? [
                {
                  Icon: InvoicesSvg,
                  path: `/${hash}/invoices`,
                  name: "Invoices",
                  isAccess: data.data.doc.is_invoice,
                },
                {
                  Icon: CreditNoteSvg,
                  path: `/${hash}/credit-notes`,
                  name: "Credit Note",
                  isAccess: data.data.doc.is_credit_note,
                },
                {
                  Icon: PaymentsSvg,
                  path: `/${hash}/payments`,
                  name: "Payment",
                  isAccess: data.data.doc.is_payment,
                },
                {
                  Icon: MessagesSvg,
                  path: `/${hash}/messages`,
                  name: "Messages",
                  isAccess: data.data.doc.is_message,
                },
                {
                  Icon: RequestingCallSvg,
                  path: `/${hash}/requesting-calls`,
                  name: "Call Back",
                  isAccess: data.data.doc.is_callback,
                },
                {
                  Icon: RequestingTimeSvg,
                  path: `/${hash}/requesting-times`,
                  name: "Req Time",
                  isAccess: data.data.doc.is_request_back,
                },
              ].map((props) => {
                return (
                  props.isAccess && (
                    <div className="sidebar-middle__menu">
                      <IconCompo {...props} />
                    </div>
                  )
                );
              })
            : ""}
        </div>

        {/* <div className="sidebar-bottom">
          {[
            { Icon: NotificationIcon, name: "Notification" },
            { Icon: SettingsIcon, path: "/Setting", name: "Setting" },
            { Icon: ProfileIcon },
          ].map((props) => {
            return <IconCompoPop {...props} />;
          })}
        </div> */}
      </div>
    </>
  );
};

export default SidebarMobileView;
